.intro {
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .intro--1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        max-width: 50vw;
        height: auto;
    }

    .played_games {
        width: 100vw;
    }
}