@import url(https://fonts.googleapis.com/css?family=Orbitron:500&text=0123456789:AMP);
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes slideIn {
  0% {
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes slideAndFadeIn {
  0% {
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes slideAndFadeIn {
  0% {
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes slideUpAndFadeOut {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, -40px, 0);
            transform: translate3d(0, -40px, 0);
    opacity: 0; } }

@keyframes slideUpAndFadeOut {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, -40px, 0);
            transform: translate3d(0, -40px, 0);
    opacity: 0; } }

* {
  box-sizing: border-box; }

html, body {
  font-family: 'Avenir Next',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  background: #161616;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-size: 16px; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 700; }

.content {
  line-height: 1.6em;
  font-size: 16px; }

.container {
  width: 100vw;
  padding: 0 24px; }

.btn {
  -webkit-appearance: none;
  border: 1px solid #282828;
  background: #282828;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  height: 56px;
  font-size: 1rem;
  line-height: 1.8;
  border-radius: 0.25rem;
  transition: 0.15s ease;
  cursor: pointer;
  text-decoration: none; }
  .btn.btn-primary {
    border-color: #34B6B1;
    background: #34B6B1; }
  .btn.btn--replay svg {
    width: 16px;
    height: auto;
    margin-right: 8px; }

.btn--group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  width: 100%; }
  .btn--group .btn {
    margin-right: 16px; }
    .btn--group .btn:last-child {
      margin-right: 0; }
  .btn--group .btn-primary {
    flex-grow: 1; }

.loading.loading--fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading.loading--fullscreen .loader {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid white;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    -webkit-animation: spin 1.3s cubic-bezier(0.7, 0.21, 0.35, 0.8) infinite;
            animation: spin 1.3s cubic-bezier(0.7, 0.21, 0.35, 0.8) infinite; }

.modal__outer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; }
  .modal__outer .modal__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px); }
  .modal__outer .modal__inner {
    position: relative;
    z-index: 9;
    width: calc(100vw - 48px);
    height: auto;
    padding: 24px 24px 12px;
    border-radius: 4px;
    color: #fff;
    background: #282828;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5); }
    .modal__outer .modal__inner .btn {
      width: 100%;
      margin-top: 16px; }

.timer {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  background-color: #282828;
  font-family: 'Orbitron';
  opacity: 0;
  -webkit-animation: fadeIn 0.3s ease forwards;
          animation: fadeIn 0.3s ease forwards; }

.timer_reveal h1 {
  font-family: 'Orbitron';
  color: red; }

.template--outer {
  transition: 0.3s ease;
  min-height: 100vh; }
  .template--outer h1 {
    margin-bottom: 12px;
    padding-top: 24px;
    font-size: 26px; }
    .template--outer h1:empty {
      display: none; }

.timer_active .template--outer {
  min-height: calc(100vh - 48px); }

.timer_active .template--fullscreen_video,
.timer_active .template--fullscreen_video--youtube,
.timer_active .template--location {
  height: calc(100vh - 48px); }

.template {
  padding: 24px 24px 40px; }
  .template .btn {
    width: 100%;
    margin-top: 24px; }
  .template .content {
    font-size: 16px;
    line-height: 1.8em; }
    .template .content h1 {
      margin-bottom: 24px; }
    .template .content img {
      width: 100vw;
      margin-left: -24px;
      height: auto; }
    .template .content a {
      color: #fff;
      text-decoration: underline; }
    .template .content iframe {
      width: 100vw;
      margin-left: -24px;
      min-height: 200px; }

body.menu--open .template--outer {
  -webkit-transform: translate3d(-80px, 0, 0);
          transform: translate3d(-80px, 0, 0); }

/* Template fullscreen video */
.template--fullscreen_video {
  padding: 0;
  min-height: 100vh; }

.template--fullscreen_video--youtube {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden; }
  .template--fullscreen_video--youtube .controls {
    position: relative;
    z-index: 2; }
  .template--fullscreen_video--youtube iframe {
    height: 100vh;
    position: relative;
    z-index: 1; }

.template--video {
  padding-top: 0; }
  .template--video .gif {
    margin: 0 -24px;
    width: 100vw;
    height: auto;
    margin-bottom: 24px; }

/* Template location */
.template--location {
  padding: 0;
  height: 100vh;
  overflow: hidden; }
  .template--location .location_meters {
    position: fixed;
    height: 64px;
    width: calc(100vw - 112px);
    z-index: 999;
    background: #000;
    bottom: 16px;
    left: 16px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #282828;
    border: 1px solid #282828; }
    .template--location .location_meters span {
      color: #868686; }
    .template--location .location_meters h3 {
      font-size: 24px;
      margin: 0;
      margin-left: 16px; }
  .template--location .leaflet-container {
    height: 100%; }
  .template--location .leaflet-tile-container img {
    -webkit-filter: brightness(50%) contrast(150%) grayscale(100%);
            filter: brightness(50%) contrast(150%) grayscale(100%); }
  .template--location .leaflet_user_icon,
  .template--location .leaflet_target_icon {
    display: flex; }
    .template--location .leaflet_user_icon > div,
    .template--location .leaflet_target_icon > div {
      width: 12px;
      height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
    .template--location .leaflet_user_icon .icon_inner,
    .template--location .leaflet_target_icon .icon_inner {
      width: 20px;
      height: 20px;
      background-color: #34B6B1;
      border: 3px solid #fff;
      border-radius: 16px;
      z-index: 99;
      position: absolute; }
    .template--location .leaflet_user_icon .icon_pulse,
    .template--location .leaflet_target_icon .icon_pulse {
      width: 0px;
      height: 0px;
      opacity: 0;
      background-color: #fff;
      border-radius: 9999px;
      position: absolute;
      -webkit-animation: icon_pulse 2s linear infinite;
              animation: icon_pulse 2s linear infinite;
      z-index: 1; }
  .template--location .leaflet_target_icon .icon_inner {
    background-color: #fff; }
  .template--location .leaflet-control-attribution {
    display: none; }

@-webkit-keyframes icon_pulse {
  0% {
    opacity: 0;
    width: 0px;
    height: 0px; }
  50% {
    width: 40px;
    height: 40px;
    opacity: 0.4; }
  100% {
    opacity: 0;
    width: 80px;
    height: 80px; } }

@keyframes icon_pulse {
  0% {
    opacity: 0;
    width: 0px;
    height: 0px; }
  50% {
    width: 40px;
    height: 40px;
    opacity: 0.4; }
  100% {
    opacity: 0;
    width: 80px;
    height: 80px; } }

.input--code {
  display: flex;
  flex-direction: column;
  margin-top: 40px; }
  .input--code .input {
    width: 100%; }
    .input--code .input input[type="text"] {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;
      padding: 0 20px; }
  .input--code .btn {
    width: 100%;
    margin-top: 16px; }
  .input--code.incorrect .input {
    -webkit-animation: shake 0.4s ease-in-out infinite;
            animation: shake 0.4s ease-in-out infinite; }

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  20% {
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  40% {
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  60% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  80% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes shake {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  20% {
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  40% {
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  60% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  80% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.btn--menu {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 64px;
  height: 64px;
  border-radius: 40px;
  z-index: 9999; }
  .btn--menu svg {
    fill: #fff;
    width: 32px;
    height: 32px; }

.menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  pointer-events: none; }
  .menu .overlay {
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease; }
  .menu nav {
    background-color: #282828;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 70vw;
    top: 0;
    right: -70vw;
    height: 100vh;
    padding: 40px 24px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 10;
    pointer-events: none;
    transition: 0.3s ease; }
  .menu .nav-link {
    color: #fff;
    display: flex;
    -webkit-appearance: none;
    font-size: 16px;
    text-decoration: none;
    background: none;
    border: 0;
    padding: 12px 0;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%; }
  .menu.menu--open {
    pointer-events: all; }
    .menu.menu--open .overlay {
      opacity: 1;
      pointer-events: all; }
    .menu.menu--open nav {
      right: 0;
      pointer-events: all; }

.menubar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end; }
  .menubar .btn {
    padding: 24px;
    width: 80px;
    height: 80px;
    background: transparent;
    border: 0; }
    .menubar .btn svg {
      width: 32px;
      height: 32px; }
      .menubar .btn svg path:last-child {
        fill: #fff; }

.intro img {
  max-width: 100%; }

.intro--2 {
  justify-content: flex-start; }
  .intro--2 .input input[type="text"] {
    text-transform: none; }

.intro {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .intro .intro--1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .intro .logo {
    max-width: 50vw;
    height: auto; }
  .intro .played_games {
    width: 100vw; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

.input {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .input input[type="text"],
  .input input[type="number"] {
    height: 56px;
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    padding: 0 20px; }
  .input .error {
    width: 100%;
    font-size: 14px;
    padding-top: 12px; }

.post_game {
  min-height: 100vh; }
  .post_game .scoreboard {
    padding: 40px 24px;
    background-color: #282828;
    min-height: calc(100vh - 300px); }
    .post_game .scoreboard h3 {
      font-size: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px; }
      .post_game .scoreboard h3 span {
        font-size: 14px;
        color: #868686;
        font-weight: 400; }
    .post_game .scoreboard .scoreboard_list {
      margin: 0 -24px; }
    .post_game .scoreboard .scoreboard_item {
      padding: 0 24px;
      display: flex;
      align-items: center; }
      .post_game .scoreboard .scoreboard_item .team_place {
        width: 20px;
        margin-right: 12px; }
      .post_game .scoreboard .scoreboard_item .team_picture {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-size: cover;
        background-position: center;
        margin-right: 16px; }
      .post_game .scoreboard .scoreboard_item .team_name {
        font-size: 16px;
        padding: 24px 0;
        flex-grow: 1;
        border-bottom: 1px solid #4C4C4C; }
      .post_game .scoreboard .scoreboard_item .team_score {
        color: #868686;
        padding: 24px 0;
        border-bottom: 1px solid #4C4C4C; }
      .post_game .scoreboard .scoreboard_item.scoreboard_item--self {
        background-color: rgba(255, 255, 255, 0.05); }

.score_reveal {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  padding: 24px;
  opacity: 1;
  flex-direction: column;
  background-color: #161616;
  z-index: 99;
  pointer-events: none; }
  .score_reveal span {
    color: #868686; }
  .score_reveal p {
    text-align: center;
    color: #CCCCCC; }
  .score_reveal.animate_in span, .score_reveal.animate_in h1, .score_reveal.animate_in p {
    opacity: 0;
    -webkit-animation: slideAndFadeIn 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
            animation: slideAndFadeIn 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards; }
  .score_reveal.animate_out {
    -webkit-animation: fadeOut 1s ease-in-out 2s forwards;
            animation: fadeOut 1s ease-in-out 2s forwards; }
    .score_reveal.animate_out span, .score_reveal.animate_out h1, .score_reveal.animate_out p {
      -webkit-animation: slideUpAndFadeOut 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
              animation: slideUpAndFadeOut 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards; }

.user_score {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px 40px 24px; }
  .user_score .team_picture {
    height: 100px;
    width: 100px;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
    margin-bottom: 16px; }
  .user_score .team_name {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px; }
  .user_score .user_stats {
    display: flex; }
    .user_score .user_stats > div {
      width: 50%; }
      .user_score .user_stats > div span {
        color: #868686;
        padding-bottom: 6; }
      .user_score .user_stats > div h3 {
        font-weight: 700;
        margin: 0; }
      .user_score .user_stats > div:first-child {
        padding-right: 40px;
        border-right: 1px solid #868686;
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
      .user_score .user_stats > div:last-child {
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start; }

.puzzle-a {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .puzzle-a img {
    width: calc(100vw - 24px);
    height: auto;
    max-width: 400px; }

.template--puzzle_a .link--puzzle_a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  margin: 32px 0;
  border-radius: 3px;
  font-size: 18px;
  text-decoration: underline; }

