.post_game {
    min-height: 100vh;

    .scoreboard {
        padding: 40px 24px;
        background-color: $black_light;
        min-height: calc(100vh - 300px);

        h3 {
            font-size: 26px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;

            span {
                font-size: 14px;
                color: #868686;
                font-weight: 400;
            }
        }
        .scoreboard_list {
            margin: 0 -24px;
        }
        .scoreboard_item {
            padding: 0 24px;
            display: flex;
            align-items: center;

            .team_place {
                width: 20px;
                margin-right: 12px;
            }

            .team_picture {
                width: 40px;
                height: 40px;
                border-radius: 20px;
                background-size: cover;
                background-position: center;
                margin-right: 16px;
            }

            .team_name {
                font-size: 16px;
                padding: 24px 0;
                flex-grow: 1;
                border-bottom: 1px solid #4C4C4C;
            }

            .team_score {
                color: #868686;
                padding: 24px 0;
                border-bottom: 1px solid #4C4C4C;                
            }

            &.scoreboard_item--self {
                background-color: rgba(255,255,255,0.05);
            }
        }
    }
}
.score_reveal {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    padding: 24px;
    opacity: 1;
    flex-direction: column;
    background-color: $black;
    z-index: 99;
    pointer-events: none;

    span {
        color: #868686;
    }
    p {
        text-align: center;
        color: #CCCCCC;
    }

    &.animate_in {
        span, h1, p {
            opacity: 0;
            animation: slideAndFadeIn 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;       
        }
    }


    &.animate_out {
        animation: fadeOut 1s ease-in-out 2s forwards;

        span, h1, p {
            animation: slideUpAndFadeOut 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
        }
    }
}

.user_score {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px 40px 24px;

    .team_picture {
        height: 100px;
        width: 100px;
        border-radius: 100px;
        background-size: cover;
        background-position: center;
        margin-bottom: 16px;
    }
    .team_name {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    .user_stats {
        display: flex;

        > div {
            width: 50%;

            span {
                color: #868686;
                padding-bottom: 6;
            }

            h3 {
                font-weight: 700;
                margin: 0;
            }

            &:first-child {
                padding-right: 40px;
                border-right: 1px solid #868686;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            &:last-child {
                padding-left: 40px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

    }
}