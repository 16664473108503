* {
    box-sizing: border-box;
}
html, body {
    font-family: 'Avenir Next',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    background: $black;
    color: #fff;   
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0; 
    font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 700;
}

.content {
    line-height: 1.6em;
    font-size: 16px;
}

.container {
    width: 100vw;
    padding: 0 24px;
}

.btn {
    -webkit-appearance: none;
    border: 1px solid $black_light;
    background: $black_light;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    border: 1px solid transparent;
    height: 56px;
    font-size: 1rem;
    line-height: 1.8;
    border-radius: 0.25rem;
    transition: 0.15s ease;
    cursor: pointer;
    text-decoration: none;

    &.btn-primary {
        border-color: $primary;
        background: $primary;
    }

    &.btn--replay {
        svg {
            width: 16px;
            height: auto;
            margin-right: 8px;
        }
    }
}

.btn--group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 24px;
    width: 100%;

    .btn{
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }

    .btn-primary {
        flex-grow: 1;
    }
}

.loading{

    &.loading--fullscreen {
        position: fixed;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .loader {
            border: 3px solid rgba(255,255,255,0.3);
            border-top: 3px solid rgba(255,255,255,1);
            border-radius: 50%;
            width: 64px;
            height: 64px;
            animation: spin 1.3s cubic-bezier(0.7, 0.21, 0.35, 0.8) infinite;   
        }
    }
} 

.modal__outer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    
    .modal__background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.7);
        backdrop-filter: blur(3px);
    }

    .modal__inner {
        position: relative;
        z-index: 9;
        width: calc(100vw - 48px);
        height: auto;
        padding: 24px 24px 12px;
        border-radius: 4px;
        color: #fff;
        background: $black_light;
        box-shadow: 0px 10px 20px rgba(0,0,0,0.5);

        .btn {
            width: 100%;
            margin-top: 16px;
            // background-color: $black;
        }
    }
}