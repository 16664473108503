.puzzle-a {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
        width: calc(100vw - 24px);
        height: auto;
        max-width: 400px;
    }
}

.template--puzzle_a {
    .link--puzzle_a {
        color: #fff;
        // background: $primary;
        text-decoration: none;
        font-weight: 600;
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: flex-start;
        align-items: center;
        margin: 32px 0;
        border-radius: 3px;
        font-size: 18px;
        text-decoration: underline;
    }
}