.btn--menu {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 64px;
    height: 64px;
    border-radius: 40px;
    z-index: 9999;

    svg {
        fill: #fff;
        width: 32px;
        height: 32px;
    }
}

.menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    pointer-events: none;

    .overlay {
        background-color: rgba(0,0,0,0.7);
        z-index: 6;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s ease;        
    }

    nav {
        background-color: $black_light;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 70vw;
        top: 0;
        right: -70vw;
        height: 100vh;
        padding: 40px 24px;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        z-index: 10;
        pointer-events: none;
        transition: 0.3s ease;   
    }

    .nav-link {
        color: #fff;
        display: flex;
        -webkit-appearance: none;
        font-size: 16px;
        text-decoration: none;
        background: none;
        border: 0;
        padding: 12px 0;
        margin: 0;
        border-bottom: 1px solid rgba(255,255,255,0.2);
        width: 100%;
    }

    &.menu--open {
        pointer-events: all;

        .overlay {
            opacity: 1;
            pointer-events: all;
        }

        nav {
            right: 0;
            pointer-events: all;
        }
    }
}

.menubar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .btn {
        padding: 24px;
        width: 80px;
        height: 80px;
        background: transparent;
        border: 0;

        svg {
            width: 32px;
            height: 32px;
            path:last-child  {
                fill: #fff;
            }
        }
    }
}