input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.input {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input[type="text"],
    input[type="number"]{
        height: 56px;
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-appearance: none;
        border: 1px solid rgba(255,255,255,0.3);
        border-radius: 3px;
        background-color: rgba(255,255,255,0.1);
        color: #fff;
        // font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
        padding: 0 20px;
    }

    .error {
        width: 100%;
        font-size: 14px;
        padding-top: 12px;
    }
}