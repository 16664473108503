@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes slideIn {
    0% {
        transform: translate3d(0, 40px, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;        
    }
}

@keyframes spin {
    0% { 
        transform: rotate(0deg); 
    }
    100% { 
        transform: rotate(360deg); 
    }
}

@keyframes slideAndFadeIn {
    0% {
        transform: translate3d(0, 40px, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;        
    }
}

@keyframes slideUpAndFadeOut {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0, -40px, 0);
        opacity: 0;        
    }
}