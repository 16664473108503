.intro {
    img {
        max-width: 100%;
    }
}
.intro--2 {
    justify-content: flex-start;

    .input input[type="text"]{
        text-transform: none;
    }
}