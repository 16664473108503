// @import 'https://fonts.googleapis.com/css?family=Orbitron&text=0123456789:AMP';
@import url('https://fonts.googleapis.com/css?family=Orbitron:500&text=0123456789:AMP');

.timer {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    background-color: $black_light;
    font-family: 'Orbitron';
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
}
.timer_reveal {
    h1 {
        font-family: 'Orbitron';
        color: red;     
    }
}

.template--outer {
    // position: absolute;
    // max-height: 100vh;
    // left: 0;
    // top: 0;
    // width: 100%;
    // overflow-x: hidden;
    // overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;
    // opacity: 0;
    // transform: translate3d(0, 40px, 0);
    // animation: slideIn 0.5s ease forwards;
    transition: 0.3s ease;
    min-height: 100vh;

    h1 {
        margin-bottom: 12px;
        padding-top: 24px;
        font-size: 26px;

        &:empty {
            display: none;
        }
    }
}

.timer_active {
    .template--outer {
        min-height: calc(100vh - 48px);
    }
    .template--fullscreen_video,
    .template--fullscreen_video--youtube,
    .template--location,{
        height: calc(100vh - 48px);        
    }
}

.template {
    padding: 24px 24px 40px;

    .btn {
        width: 100%;
        margin-top: 24px;
    }

    .content {
        font-size: 16px;
        line-height: 1.8em;

        h1 {
            margin-bottom: 24px;
        }

        img {
            width: 100vw;
            margin-left: -24px;
            height: auto;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }

        iframe {
            width: 100vw;
            margin-left: -24px;
            min-height: 200px; 
        }
    }
}

body.menu--open .template--outer {
    transform: translate3d(-80px, 0, 0);
}


/* Template fullscreen video */
.template--fullscreen_video {
    padding: 0;
    min-height: 100vh;
}
.template--fullscreen_video--youtube {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;

    .controls {
        position: relative;
        z-index: 2;
    }

    iframe {
        height: 100vh;
        position: relative;
        z-index: 1;
    }
}

.template--video {
    padding-top: 0;

    .gif {
        margin: 0 -24px;
        width: 100vw;
        height: auto;
        margin-bottom: 24px;
    }
}

/* Template location */

.template--location {
    padding: 0;
    height: 100vh;
    overflow: hidden;

    .location_meters {
        position: fixed;
        height: 64px;
        width: calc(100vw - 112px);
        z-index: 999;
        background: #000;
        bottom: 16px;
        left: 16px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $black_light;
        border: 1px solid $black_light;

        span {
            color: #868686;
        }
        h3 {
            font-size: 24px;
            margin: 0;
            margin-left: 16px;
        }
    }

    .leaflet-container {
        height: 100%;
    }

    .leaflet-tile-container {
        img {
            filter: brightness(50%) contrast(150%) grayscale(100%);
        }
    }

    .leaflet_user_icon,
    .leaflet_target_icon {
        display: flex;

        > div {
            width: 12px;
            height: 12px; 
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;          
        }
        .icon_inner {
            width: 20px;
            height: 20px;
            background-color: $primary;
            border: 3px solid #fff;
            border-radius: 16px;
            z-index: 99;
            position: absolute;
        }   
        .icon_pulse {
            width: 0px;
            height: 0px;
            opacity: 0;
            background-color: #fff;
            border-radius: 9999px;
            position: absolute;
            animation: icon_pulse 2s linear infinite;
            z-index: 1;
        }
    }

    .leaflet_target_icon {
        .icon_inner {
            background-color: #fff;
        }
    }

    .leaflet-control-attribution {
        display: none;
    }
}

@keyframes icon_pulse {
    0% {
        opacity: 0;
        width: 0px;
        height: 0px;
    }
    50% {
        width: 40px;
        height: 40px;
        opacity: 0.4;
    }
    100% {
        opacity: 0;
        width: 80px;
        height: 80px;
    }
}




.input--code {
    display: flex;
    flex-direction: column;
    margin-top: 40px
    ;
    .input {
        width: 100%;
        
        input[type="text"] {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;
            padding: 0 20px;
        }
    }
    .btn {
        width: 100%;
        margin-top: 16px;
    }

    &.incorrect {
        .input {
            animation: shake 0.4s ease-in-out infinite;
        }
    }
}

@keyframes shake {
    0% {
        transform: translate3d(0, 0, 0);
    }
    20% {
        transform: translate3d(20px,0,0);
    }
    40% {
        transform: translate3d(-20px,0,0);
    }
    60% {
        transform: translate3d(10px,0,0);
    }
    80% {
        transform: translate3d(-10px,0,0);
    }
    100% {
        transform: translate3d(0,0,0);
    }
}